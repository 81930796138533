<template>
	<div class="page_content">
		<div class="page_detail">
			
			<a-divider orientation="left">群发模版</a-divider>
			<div class="push_box">
				<div class="push_title">润田公司销售出库单-示例</div>
				<div class="push_item">
					<span class="lable_text">反馈时间：</span><span>大余县安府商行</span>
				</div>
				<div class="push_item">
					<span class="lable_text">反馈内容：</span><span> 0000627163</span>
				</div>
				<div class="push_item">
					<span class="lable_text">回复内容：</span><span>20,030.00元</span>
				</div>
                <div class="push_item">
                	<span class="lable_text">后置语：</span><span>20,030.00元</span>
                </div>
				<p class="push_item go_more">
					<span>详情</span><span><a-icon type="right" /></span>
				</p>
			</div>
            <Form ref="form_data" style="margin-left: -150px;" @handleChange="handleChange" @handleSubmit="handleSubmit"
                :form_data="form_data">
               
            </Form>
<!-- 			<a-descriptions :column="1">
				<a-descriptions-item label="接收人">
					<p>
                        
                        <a-checkbox @change="onChange">
                            员工
                        </a-checkbox>
                        <a-checkbox @change="onChange">
                              经销商
                        </a-checkbox>
					</p>
				</a-descriptions-item>
				<a-descriptions-item label="接收区域 ">
					<p>接收区域</p>
				</a-descriptions-item>
                <a-descriptions-item label="接收组 ">
                	<p>接收组</p>
                </a-descriptions-item>
                <a-descriptions-item label="前置语 ">
                	<p>接收组</p>
                </a-descriptions-item>
			</a-descriptions> -->
            <div>
                
            </div>
			<div class="box_form box_form_btns">
				<a-button type="defalut" @click="$router.back()">返回</a-button>
			</div>
		</div>

	</div>
</template>

<script>
	import EditPop from "@/components/EditPop";
	import LookImages from "@/components/LookImages";
	import {
		getUserData,
		modifyUser,
		getRule,
		getAuthList,
		cancelBinding
	} from "@/api/user";
	
	import {
		setting_detail,setting_index,setting_del
	} from '@/api/push.js'
     import Form from "@/components/Form";
	import { getUserList } from "@/api/personnel";
	import SelectUser from "@/components/SelectUser";
	import QRCode from 'qrcodejs2'
	export default {
		components: {
			LookImages,
            Form,
			EditPop,
			SelectUser
		},
		data() {
			return {
				code_src:'',
				select_user:{
					
				},
				primary:1,
				drum:[],
				bottled:[],
				user_visible:false,
				allchecked: false,
				allchecked2: false,
				activeKey: 1,
				size: 'default',
				activeKey: 1,
				visible: false,
				form_data: {
				    show_submit_btn: true,
				    show_close_btn: false,
				    close_reset_btn: true,
				    list: [
                        {
                            type: "checkbox",
                            name: "clock_first_mode1",
                            title: "打卡方式",
                            options: {
                                initialValue: []
                            },
                            list: [{
                                    key: 'photo',
                                    value: '员工'
                                },
                                {
                                    key: 'position',
                                    value: '经销商'
                                }
                            ]
                        },
				        {
				            type: "tree-select",
				            name: "department_id1",
				            title: "接收区域",
				            options: {},
				            treeData: [],
				            multiple: true
				        },
				        {
				            type: "select",
				            name: "role_id1",
				            title: "接收组",
				            mode: "default",
				            options: {},
				            list: [],
				            mode: 'multiple'
				        },
				    ]
				},
				detail: {
					trip: {},
					check_in: []
				},
				con_lit: []
			}
		},
		created() {
			 // this.get_info()
			
		},
		methods: {
            handleSubmit(){},
            handleChange(){},
			
			add_user() {
				this.user_visible = true;
			},
			submit2({
				data
			}) {
				this.user_visible = false;
				this.select_user = data;
				this.creatQrCode()
				
			},
		

			
			
			submit(e) {
				this.openid=e.values.openid
				this.uid=this.select_user.id
				this.visible = false;
				this.changeStatus('user')
			},
			
			get_info() {
				setting_detail().then(res => {
					let temp1= 0
					let temp2= 0
					this.url = res.data.url
					this.drum = res.data.data.drum;
					this.con_lit= res.data.user
					this.bottled=res.data.data.bottled
					this.drum.forEach(item=>{
						if(item.value==1){
							item.value=true
						}else{
							temp1++
							item.value=false
						}
					})
					if(temp1>0){
						this.allchecked=false
					}else {
						this.allchecked=true
					}
					this.bottled.forEach(item=>{
						if(item.value==1){
							item.value=true
						}else{
							temp2++
							item.value=false
						}
					})
					
					if(temp2>0){
						this.allchecked2=false
					}else {
						this.allchecked2=true
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.push_title {
		font-size: 16px;
		font-weight: bold;
		margin-bottom: 8px;
	}

	.push_box {
		width: 500px;
		height: auto;
		padding: 16px;
		border: 1px solid #ccc;
		margin-bottom: 12px;
		padding-bottom: 9px;
	}

	.page_detail {
		margin: 0;
		margin-left: 5%;
	}

	.push_item {
		height: auto;
		line-height: 40px;
		.lable_text {
			width: 70px;
			display: inline-block;
		}

		display: flex;

		.good_item {
			height: 70px;

			p {
				height: 20px;
			}
		}
	}

	.go_more {
		margin-top: 10px;
		border-top: 1px solid #ccc;
		display: flex;
		align-items: center;
		span:nth-child(1) {
			flex: 4;
			margin-top: 10px;
		}

		span:nth-child(2) {
			margin-top: 5px;
			flex: 1;
			text-align: right;
		}
	}

	.add_man {
		color: #00A3FF;
        margin-top: 30px;
	}

	.cont_box {
		align-items: center;
		display: flex;

		.cont_item {
			margin-top: 10px;
            width: 300px;
			p {
				height: 28px;
				line-height: 28px;
				// width: 460px;
			}
		}

		.del_iocn {
			margin-left: 35px;
			margin-top: 10px;
		}

	}

	.checked_btn {
		margin-top: 20px;
	}

	.tab_box {
		display: flex;
		width: 400px;
		margin-top: 10px;
	}

	.tab_title {
		flex: 1;
		display: flex;

		.tab_item {
			flex: 1;

			div {
				display: flex;


				p {
					height: 40px;
					width: 150px;
					text-align: center;
					line-height: 40px;
					border: 1px solid #ccc;
				}

			}

			.title_box {
				flex-direction: row;
			}

			.item_box {
				flex-direction: column;
				display: flex;

				div {
					flex-direction: row;

					p {
						height: 40px;
						width: 150px;
						text-align: center;
						line-height: 40px;
						border: 1px solid #ccc;
					}
				}
			}
		}

	}
	.right_btn{
		margin-left: 10px;
	}
	
</style>
